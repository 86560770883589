import React, { useEffect } from 'react'
import { graphql, withPrefix } from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

import * as styles from '../styles/article.module.sass'

const Post = ({ data }) => {
  const post = data.thePost
  const content = post.content.replace(/href="\/(.*?)"/g, (m, $1) => 'href="' + withPrefix($1) + '"')

  useEffect(() => {
    const floatContentGallery = () => {
      let placeholderImage = function (width, height) {
        return "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http://www.w3.org/2000/svg'%20viewBox%3D'0%200%20" + width + '%20' + height + "'%20%2F%3E"
      }

      for (const gallery of document.querySelectorAll('.content-gallery')) {
        let heights = Array.from(gallery.children).map((figure) => figure.clientHeight)
        let max = Math.max(...heights)
        let siblings = heights.filter((value) => value !== max)
        if (!siblings.length) return
        let siblingsMax = Math.max(...siblings)
        let height = max - siblingsMax
        let width = gallery.children[gallery.children.length - 1].clientWidth
        if (height > 100) {
          // console.log(siblings)
          let marginBottom = (((width * 100) / gallery.clientWidth) * height) / width
          gallery.style.marginBottom = -marginBottom + '%'
          let img = document.createElement('img')
          img.src = placeholderImage(width, height)
          img.className = 'float-placeholder'
          img.width = width
          img.height = height
          gallery.nextElementSibling.prepend(img)
        }
      }
    }
    floatContentGallery()
  }, [])

  return (
    <Layout
      currentUri={post.uri}
      currentLocations={post.categories.nodes}
      currentLang={post.language.locale}
      translations={post.translations}
      articleType="article"
    >
      <Seo title={post.title} lang={post.language.locale.slice(0, 2)} />
      <article className={styles.article} dangerouslySetInnerHTML={{ __html: content }} />
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ($databaseId: Int!) {
    thePost: wpPost(databaseId: { eq: $databaseId }) {
      date
      databaseId
      content
      title
      uri
      categories {
        nodes {
          slug
          uri
          name
          id
          description
          posts {
            nodes {
              title
              uri
              id
            }
          }
        }
      }
      language {
        locale
      }
      translations {
        uri
        slug
        language {
          locale
        }
      }
    }
  }
`
